import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/en/Layout'
import PostList from '../../components/PostList'
import PostCarousel from '../../components/PostCarousel'
import SEO from '../../components/SEO'

const IndexPage = ({ data }) => {
  const posts = data.allPostsEn.edges
  const sliderPosts = data.allSliderEn.edges[0]?.node?.posts

  return (
    <Layout>
      <SEO
        title="Home"
        canonical={`${process.env.GATSBY_HOST_URL}/en`}
        isAlternate={true}
        alternateUrl={`${process.env.GATSBY_HOST_URL}/pt`}
      />

      <div>
        <PostCarousel posts={sliderPosts.slice(0, 3)} lang="en" />

        <PostList posts={posts} />
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allPostsEn(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          id
          name
          slug
          excerpt
          date(formatString: "MM/DD/YYYY", locale: "en-US")
          imageUrl
          language {
            slug
          }
          meta {
            content
          }
        }
      }
    }

    allSliderEn {
      edges {
        node {
          posts {
            name
            slug
            imageUrl
          }
        }
      }
    }
  }
`
